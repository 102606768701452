import { useCountries } from "context/countryContext"
import { navigate } from "gatsby"
import React, { useEffect } from "react"
import routes from "services/routes"

/**
 * An index page for the Store that will redirect to the
 * correct Store country or the Store Chooser if one cannot be determined.
 */
const StoreIndex = () => {
  // Store country
  const { isLoading, storeURL } = useCountries()

  useEffect(() => {
    if (isLoading) {
      return
    }
    // Navigate to the store for this country (or the store chooser)
    navigate(routes.STORE_FEATURED(storeURL))
  }, [isLoading, storeURL])

  return <></>
}

export default StoreIndex
StoreIndex.whyDidYouRender = true
